import React from "react";
import { useOrder } from "../../../hooks/useOrder";
import { useAuth } from "../../../hooks/useAuth";
import OrderHistoryTable from "../../../components/OrderHistoryTable"; 
import { useNavigate } from "react-router-dom";

function RegularOrderHistory() {
  const { userDetails } = useAuth();
  const { getUserOrders } = useOrder();
  const [orders, setOrders] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (userDetails?.user_id) {
      getUserOrders(userDetails.user_id).then((userOrders) => {
        // sort orders as needed
        setOrders(userOrders);
      });
    }
  }, [userDetails, getUserOrders]);

  return (
    <OrderHistoryTable
      orders={orders}
      isAdmin={false}
      onRowClick={(orderId) => navigate(`/dashboard/order/${orderId}`)}
    />
  );
}

export default RegularOrderHistory;
