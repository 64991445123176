import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  Tooltip,
  Avatar,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Edit as EditIcon,
  Download as DownloadIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

function OrderHistoryTable({
  orders,
  isAdmin = false,
  onRowClick,
  onStatusChange, // function (orderId, newStatus) => Promise
  onDownloadPDF, // function (orderId) => Promise
  onDeleteOrder, // function (orderId) => Promise
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  // Responsive: adjust maximum avatars based on screen size.
  const isXs = useMediaQuery(theme.breakpoints.down("sm")); // extra small
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const maxOrderItems = isXs ? 2 : isSm ? 3 : 4;

  // Maintain a local copy of orders sorted by newest first.
  const [localOrders, setLocalOrders] = useState(() =>
    orders
      ? [...orders].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
      : []
  );
  useEffect(() => {
    if (orders) {
      setLocalOrders(
        [...orders].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
      );
    }
  }, [orders]);

  // Filter panel states
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("mostRecent");
  const [statusFilter, setStatusFilter] = useState([]); // empty means all statuses

  // Filtered orders based on search, status and sort order.
  const [filteredOrders, setFilteredOrders] = useState(localOrders);

  // For admin status menus (one per order)
  const [statusAnchor, setStatusAnchor] = useState({});

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Delete confirmation dialog state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  // Update filtered orders when dependencies change.
  useEffect(() => {
    const lowerSearch = searchTerm.toLowerCase();
    let filtered = localOrders.filter((order) => {
      const dateStr = new Date(order.created_at)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .toLowerCase();
      const matchesSearch =
        order.id.toString().includes(lowerSearch) ||
        dateStr.includes(lowerSearch) ||
        order.status.toLowerCase().includes(lowerSearch);
      const matchesStatus =
        statusFilter.length === 0 || statusFilter.includes(order.status);
      return matchesSearch && matchesStatus;
    });
    // Sort based on sortOrder.
    if (sortOrder === "mostRecent") {
      filtered = filtered.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    } else if (sortOrder === "oldest") {
      filtered = filtered.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "highestAmount") {
      filtered = filtered.sort((a, b) => b.total_amount - a.total_amount);
    } else if (sortOrder === "lowestAmount") {
      filtered = filtered.sort((a, b) => a.total_amount - b.total_amount);
    }
    setFilteredOrders(filtered);
    setPage(0);
  }, [localOrders, searchTerm, sortOrder, statusFilter]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(typeof value === "string" ? value.split(",") : value);
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRows = parseInt(event.target.value, 10);
    setRowsPerPage(newRows);
    setPage(0);
  };

  // Use theme's contrast function for the status badge.
  const getStatusBadge = (status) => {
    let color;
    let text;
    switch (status) {
      case "pending":
        color = "error";
        text = t("Dashboard.OrderHistory.Status.Pending");
        break;
      case "received":
        color = "warning";
        text = t("Dashboard.OrderHistory.Status.Received");
        break;
      case "in-progress":
        color = "info";
        text = t("Dashboard.OrderHistory.Status.InProgress");
        break;
      case "completed":
        color = "success";
        text = t("Dashboard.OrderHistory.Status.Completed");
        break;
      case "shipped":
        color = "secondary";
        text = t("Dashboard.OrderHistory.Status.Shipped");
        break;
      default:
        color = "error";
        text = t("Dashboard.OrderHistory.Status.Unknown");
    }
    return (
      <Box
        sx={{
          display: "inline-block",
          px: 1,
          py: 0.5,
          bgcolor: (theme) => theme.palette[color].main,
          borderRadius: 1,
          color: (theme) =>
            theme.palette.getContrastText(theme.palette[color].main),
        }}
      >
        {text}
      </Box>
    );
  };

  // Admin status menu handlers
  const handleStatusMenuOpen = (event, orderId) => {
    event.stopPropagation();
    setStatusAnchor((prev) => ({ ...prev, [orderId]: event.currentTarget }));
  };

  const handleStatusMenuClose = (orderId) => {
    setStatusAnchor((prev) => ({ ...prev, [orderId]: null }));
  };

  // When admin changes status, update via callback then refresh local orders.
  const handleAdminStatusChange = (orderId, newStatus) => {
    handleStatusMenuClose(orderId);
    onStatusChange(orderId, newStatus)
      .then(() => {
        setLocalOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? { ...order, status: newStatus } : order
          )
        );
      })
      .catch((err) => {
        console.error("Status update failed", err);
      });
  };

  // Delete confirmation handlers
  const handleDeleteClick = (e, orderId) => {
    e.stopPropagation();
    setOrderToDelete(orderId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (orderToDelete) {
      onDeleteOrder(orderToDelete)
        .then(() => {
          setLocalOrders((prevOrders) =>
            prevOrders.filter((order) => order.id !== orderToDelete)
          );
          setDeleteDialogOpen(false);
          setOrderToDelete(null);
        })
        .catch((err) => {
          console.error("Deletion failed", err);
          setDeleteDialogOpen(false);
          setOrderToDelete(null);
        });
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setOrderToDelete(null);
  };

  // Status filter options – you can change these labels as needed.
  const statusOptions = [
    { value: "pending", label: t("Dashboard.OrderHistory.Status.Pending") },
    { value: "received", label: t("Dashboard.OrderHistory.Status.Received") },
    {
      value: "in-progress",
      label: t("Dashboard.OrderHistory.Status.InProgress"),
    },
    { value: "completed", label: t("Dashboard.OrderHistory.Status.Completed") },
    { value: "shipped", label: t("Dashboard.OrderHistory.Status.Shipped") },
  ];

  return (
    <Paper sx={{ p: 4, borderRadius: 2 }}>
      {/* Modern Filter Panel */}
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          placeholder={t("Dashboard.OrderHistory.Search")}
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          sx={{ flex: 1 }} // This makes the search field fill remaining space.
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Sort By</InputLabel>
          <Select label="Sort By" value={sortOrder} onChange={handleSortChange}>
            <MenuItem value="mostRecent">
              {t("Dashboard.OrderHistory.Sort.MostRecent")}
            </MenuItem>
            <MenuItem value="oldest">
              {t("Dashboard.OrderHistory.Sort.Oldest")}
            </MenuItem>
            <MenuItem value="highestAmount">
              {t("Dashboard.OrderHistory.Sort.HighestAmount")}
            </MenuItem>
            <MenuItem value="lowestAmount">
              {t("Dashboard.OrderHistory.Sort.LowestAmount")}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            multiple
            value={statusFilter}
            onChange={handleStatusFilterChange}
            renderValue={(selected) => selected.join(", ")}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={statusFilter.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {filteredOrders.length === 0 ? (
        <Typography>{t("Dashboard.OrderHistory.NoOrders")}</Typography>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 100 }}>
                    {t("Dashboard.OrderHistory.Order")}
                  </TableCell>
                  <TableCell sx={{ width: 120 }}>
                    {t("Dashboard.OrderHistory.Date")}
                  </TableCell>
                  {isAdmin && (
                    <TableCell sx={{ width: 150 }}>
                      {t("Dashboard.OrderHistory.Customer")}
                    </TableCell>
                  )}
                  <TableCell sx={{ width: 100 }}>
                    {t("Dashboard.OrderHistory.Total")}
                  </TableCell>
                  <TableCell sx={{ width: 250 }}>
                    {t("Dashboard.OrderHistory.Items")}
                  </TableCell>
                  <TableCell sx={{ width: 80 }}>
                    {t("Dashboard.OrderHistory.Status.Label")}
                  </TableCell>
                  {isAdmin && (
                    <TableCell sx={{ width: 150 }}>
                      {t("Dashboard.OrderHistory.Actions")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, index) => (
                    <TableRow
                      key={order.id}
                      onClick={() => onRowClick(order.id)}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "background.light" : "#E0E7F0",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor:
                            index % 2 === 0
                              ? "rgba(181,195,248, 0.35)"
                              : "rgba(180,196,218, 0.45)",
                        },
                      }}
                    >
                      <TableCell>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "500", color: "primary.main" }}
                        >
                          #{order.id}
                        </Typography>
                      </TableCell>
                      <TableCell>{formatDate(order.created_at)}</TableCell>
                      {isAdmin && (
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              src={order.User?.avatar}
                              sx={{ mr: 1, width: 33, height: 33 }}
                              variant="rounded"
                            >
                              {order.User?.avatar
                                ? ""
                                : order.User?.firstname?.charAt(0)}
                            </Avatar>
                            <Typography variant="body2">
                              {order.User?.firstname} {order.User?.lastname}
                            </Typography>
                          </Box>
                        </TableCell>
                      )}
                      <TableCell>{`${order.total_amount} kr`}</TableCell>
                      <TableCell sx={{ width: 250 }}>
                        <Box
                          display="flex"
                          flexWrap="nowrap"
                          alignItems="center"
                          gap={0.5}
                        >
                          {order.OrderItems.slice(0, maxOrderItems).map(
                            (item, idx) => (
                              <Tooltip
                                key={idx}
                                title={item.Motif?.name || "Unknown Motif"}
                              >
                                <Avatar
                                  src={item.Motif?.image_url}
                                  alt={`Motif ${idx}`}
                                  variant="rounded"
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: "gray",
                                  }}
                                >
                                  <ImageNotSupportedIcon fontSize="small" />
                                </Avatar>
                              </Tooltip>
                            )
                          )}
                          {order.OrderItems.length > maxOrderItems && (
                            <Typography variant="caption" color="textSecondary">
                              +{order.OrderItems.length - maxOrderItems} more
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ width: 80 }}>
                        {getStatusBadge(order.status)}
                      </TableCell>
                      {isAdmin && (
                        <TableCell sx={{ width: 150 }}>
                          <Box display="flex" alignItems="center">
                            <Tooltip
                              title={t("Dashboard.OrderHistory.ChangeStatus")}
                              arrow
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleStatusMenuOpen(e, order.id);
                                }}
                                sx={{
                                  backgroundColor: "primary.light",
                                  borderRadius: 2,
                                  padding: 1,
                                  "& .MuiTouchRipple-root span": {
                                    borderRadius: 2,
                                  },
                                  "&:hover": {
                                    backgroundColor: "primary.main",
                                  },
                                }}
                              >
                                <EditIcon sx={{ fontSize: 15 }} />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={statusAnchor[order.id]}
                              open={Boolean(statusAnchor[order.id])}
                              onClose={(e) => {
                                e.stopPropagation();
                                handleStatusMenuClose(order.id);
                              }}
                              PaperProps={{
                                sx: {
                                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAdminStatusChange(order.id, "pending");
                                }}
                              >
                                <Badge
                                  sx={{
                                    backgroundColor: "error.main",
                                    width: 10,
                                    height: 10,
                                    borderRadius: "50%",
                                    mr: 2,
                                  }}
                                />
                                {t("Dashboard.OrderHistory.Status.Pending")}
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAdminStatusChange(order.id, "received");
                                }}
                              >
                                <Badge
                                  sx={{
                                    backgroundColor: "warning.main",
                                    width: 10,
                                    height: 10,
                                    borderRadius: "50%",
                                    mr: 2,
                                  }}
                                />
                                {t("Dashboard.OrderHistory.Status.Received")}
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAdminStatusChange(
                                    order.id,
                                    "in-progress"
                                  );
                                }}
                              >
                                <Badge
                                  sx={{
                                    backgroundColor: "info.main",
                                    width: 10,
                                    height: 10,
                                    borderRadius: "50%",
                                    mr: 2,
                                  }}
                                />
                                {t("Dashboard.OrderHistory.Status.InProgress")}
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAdminStatusChange(
                                    order.id,
                                    "completed"
                                  );
                                }}
                              >
                                <Badge
                                  sx={{
                                    backgroundColor: "success.main",
                                    width: 10,
                                    height: 10,
                                    borderRadius: "50%",
                                    mr: 2,
                                  }}
                                />
                                {t("Dashboard.OrderHistory.Status.Completed")}
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAdminStatusChange(order.id, "shipped");
                                }}
                              >
                                <Badge
                                  sx={{
                                    backgroundColor: "secondary.main",
                                    width: 10,
                                    height: 10,
                                    borderRadius: "50%",
                                    mr: 2,
                                  }}
                                />
                                {t("Dashboard.OrderHistory.Status.Shipped")}
                              </MenuItem>
                            </Menu>
                            <Tooltip
                              title={t("Dashboard.OrderHistory.DownloadPDF")}
                              arrow
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDownloadPDF(order.id);
                                }}
                                sx={{
                                  backgroundColor: "success.light",
                                  borderRadius: 2,
                                  padding: 1,
                                  "& .MuiTouchRipple-root span": {
                                    borderRadius: 2,
                                  },
                                  "&:hover": {
                                    backgroundColor: "success.main",
                                  },
                                  ml: 1,
                                }}
                              >
                                <DownloadIcon sx={{ fontSize: 15 }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={t("Dashboard.OrderHistory.DeleteOrder")}
                              arrow
                            >
                              <IconButton
                                onClick={(e) => handleDeleteClick(e, order.id)}
                                sx={{
                                  backgroundColor: "error.light",
                                  borderRadius: 2,
                                  padding: 1,
                                  "& .MuiTouchRipple-root span": {
                                    borderRadius: 2,
                                  },
                                  "&:hover": { backgroundColor: "error.main" },
                                  ml: 1,
                                }}
                              >
                                <ClearIcon sx={{ fontSize: 15 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredOrders.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </>
      )}

      {isAdmin && (
        <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
          <DialogTitle>
            {t("Dashboard.OrderHistory.DeleteConfirmTitle")}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {t("Dashboard.OrderHistory.DeleteConfirmMessage", {
                id: orderToDelete,
              })}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              {t("Dashboard.OrderHistory.Cancel")}
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              variant="contained"
              autoFocus
            >
              {t("Dashboard.OrderHistory.Confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}

export default OrderHistoryTable;
