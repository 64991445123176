import React from "react";
import { useOrder } from "../../../hooks/useOrder";
import OrderHistoryTable from "../../../components/OrderHistoryTable"; 
import { useNavigate } from "react-router-dom";

function AdminOrderHistory() {
  const { getAllOrders, updateOrderStatus, downloadOrderPDF, deleteOrder } = useOrder();
  const [orders, setOrders] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    getAllOrders().then((allOrders) => {
      // sort orders as needed
      setOrders(allOrders);
    });
  }, [getAllOrders]);

  return (
    <OrderHistoryTable
      orders={orders}
      isAdmin={true}
      onRowClick={(orderId) => navigate(`/dashboard/order/${orderId}`)}
      onStatusChange={updateOrderStatus}
      onDownloadPDF={downloadOrderPDF}
      onDeleteOrder={deleteOrder}
    />
  );
}

export default AdminOrderHistory;