import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { CartContext } from "../../context/CartContext";
import usePriceCalculator from "../../components/priceCalculator";
import {
  Container,
  Paper,
  Box,
  Typography,
  List,
  Button,
  Grid,
  Card,
  Tooltip,
  CardMedia,
  IconButton,
  Divider,
  CircularProgress,
  keyframes,
  TextField,
  Collapse,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/useUser";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { useOrder } from "../../hooks/useOrder";
import { useEmail } from "../../hooks/useEmail";

const libraries = ["places"];

const CartPage = () => {
  const { cart, handleRemoveFromCart, handleClearCart } =
    useContext(CartContext);
  const { calculatePrice, priceInfo } = usePriceCalculator();
  const { userDetails } = useAuth();
  const [applyAssembly, setApplyAssembly] = useState(false); // New state for assembly
  const { user, getUserByID } = useUser();
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animationStage, setAnimationStage] = useState("initial"); // initial, shrinking, loading, success
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [orderError, setOrderError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // To store error message for the alert
  const [rememberDetails, setRememberDetails] = useState(false);
  const { sendEmail, loading: emailLoading, error: emailError } = useEmail();
  const [notes, setNotes] = useState("");
  const { updateUserProfile } = useUser();
  const [formFields, setFormFields] = useState({
    firstname: "",
    lastname: "",
    company_name: "",
    address: "",
    city: "",
    postal_code: "",
    country: "",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { createOrder } = useOrder();
  const inputRef = useRef(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY,
    libraries: libraries,
  });

  if (loadError) {
    console.error("Error loading Google Maps API:", loadError);
  }

  useEffect(() => {
    if (userDetails && userDetails.user_id) {
      getUserByID(userDetails.user_id);
    }
  }, [userDetails, getUserByID]);

  useEffect(() => {
    if (user) {
      setFormFields({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        company_name: user.company_name || "",
        address: user.address || "",
        city: user.city || "",
        postal_code: user.postal_code || "",
        country: user.country || "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      cart.forEach((item) => {
        if (item.width && item.height) {
          calculatePrice(
            item.quantity,
            item.width,
            item.height,
            item.colors,
            item.id,
            userDetails?.user_id,
            { applyAssembly }
          );
        }
      });
    }
  }, [cart, calculatePrice, userDetails?.user_id, applyAssembly]);

  const subtotal = useMemo(() => {
    return cart.reduce((acc, item) => {
      const itemTotal = priceInfo[item.id]
        ? parseFloat(priceInfo[item.id].total)
        : 0;
      return acc + itemTotal;
    }, 0);
  }, [cart, priceInfo]);

  const grandTotal = useMemo(() => {
    return subtotal;
  }, [subtotal]);

  const validateFields = () => {
    let newErrors = {};
    const requiredFields = [
      "firstname",
      "lastname",
      "address",
      "city",
      "postal_code",
    ];

    requiredFields.forEach((field) => {
      if (!formFields[field]) {
        newErrors[field] = `${field.replace("_", " ")} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePlaceOrder = async () => {
    if (cart.length === 0) {
      setOrderError(true);
      setErrorMessage(t("Checkout.EmptyWarn"));
      return;
    }

    // Validate form fields before proceeding
    if (validateFields()) {
      setLoading(true);
      setAnimationStage("shrinking");

      try {
        // Wait for the shrinking animation to finish before proceeding
        setTimeout(async () => {
          try {
            // Update user details if "Remember Details" is checked
            if (rememberDetails) {
              await updateUserProfile(userDetails.user_id, {
                firstname: formFields.firstname,
                lastname: formFields.lastname,
                company_name: formFields.company_name,
                address: formFields.address,
                city: formFields.city,
                postal_code: formFields.postal_code,
                country: formFields.country,
              });
            }

            // Prepare order items and shipping details
            const orderItems = cart.map((item) => ({
              motif_id: item.id,
              quantity: item.quantity,
            }));

            const shippingDetails = {
              firstname: formFields.firstname,
              lastname: formFields.lastname,
              company_name: formFields.company_name,
              address: formFields.address,
              city: formFields.city,
              postal_code: formFields.postal_code,
              country: formFields.country,
              notes: notes || "",
              assembly_included: applyAssembly,
            };

            // Try to create the order
            const newOrder = await createOrder({
              items: orderItems,
              shippingDetails,
            });

            // await sendEmail(newOrder);

            // If order is successfully created, show the success animation
            setAnimationStage("success");
            setTimeout(() => {
              handleClearCart();
              setOrderPlaced(true);
              navigate(`/dashboard/order/${newOrder.id}`);
            }, 1000);
          } catch (error) {
            console.error("Failed to create order", error);
            setOrderError(true);
            setErrorMessage(t("Checkout.OrderError"));
            setAnimationStage("failure"); // Set failure animation stage
          } finally {
            setLoading(false); // Stop loading spinner
          }
        }, 500); // Wait for shrinking animation to finish
      } catch (error) {
        // Handle frontend validation or other unexpected errors
        console.error("An unexpected error occurred", error);
        setOrderError(true);
        setErrorMessage(t("Checkout.Error"));
        setAnimationStage("failure"); // Set failure animation stage
        setLoading(false); // Stop loading spinner
      }
    } else {
      // If validation fails, show the form errors and stop any animation
      setShowForm(true);
      setOrderError(true);
      setErrorMessage(t("Checkout.FormError"));
      setAnimationStage("failure");
      setLoading(false); // Ensure loading is stopped
    }
  };

  const handleToggleAssembly = (event) => {
    setApplyAssembly(event.target.checked);
  };

  // Function to handle place selection and autofill the form fields
  const handleOnPlacesChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0]; // Get the first place
      const addressComponents = place.address_components;

      // Helper function to extract data from the address_components array
      const getComponent = (components, type, useShortName = false) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component
          ? useShortName
            ? component.short_name
            : component.long_name
          : "";
      };

      // Update form fields based on the selected place
      const updatedFields = {
        address:
          getComponent(addressComponents, "route") +
          " " +
          getComponent(addressComponents, "street_number"),
        city:
          getComponent(addressComponents, "locality") ||
          getComponent(addressComponents, "postal_town"),
        postal_code: getComponent(addressComponents, "postal_code"),
        country: getComponent(addressComponents, "country"),
      };

      setFormFields((prev) => ({
        ...prev,
        ...updatedFields,
      }));

      // Clear errors for the fields that were auto-filled
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "",
        city: "",
        postal_code: "",
        country: "",
      }));
    }
  };

  const handleFieldChange = (e, name, value) => {
    if (name && value) {
      // When name and value are provided manually, e.g., for ReactFlagsSelect
      setFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      // For regular TextField inputs
      const { name, value } = e.target;
      setFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear the error for the specific field if it exists
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name || e.target.name]: "", // Clear error for the field
    }));
  };

  if (!user || Object.keys(user).length === 0) {
    return (
      <Container
        component="main"
        maxWidth="lg"
        sx={{ textAlign: "center", mt: 10 }}
      >
        <Paper elevation={3} sx={{ padding: 5, borderRadius: 2 }}>
          <Typography variant="h4" fontWeight={500} gutterBottom>
            {t("Checkout.NonUserTitle")}
          </Typography>
          <Typography variant="body1" mb={3}>
            {t("Checkout.NonUserSubtitle")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
          >
            {t("LogIn")}
          </Button>
        </Paper>
      </Container>
    );
  }

  const bounceAnimation = keyframes`
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(1.2); opacity: 1; }
    70% { transform: scale(1); }
    100% { transform: scale(1); }
  `;

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography variant="h4" mb={3} fontWeight={500}>
              {t("Checkout.Label")}
            </Typography>
            {cart.length === 0 ? (
              <Typography variant="body1">{t("Checkout.EmptyCart")}</Typography>
            ) : (
              <List>
                {cart.map((item) => {
                  return (
                    <Card
                      key={item.id}
                      sx={{ boxShadow: "none", marginBottom: 2 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={5} sm={4} md={3}>
                          <Link
                            to={`/dashboard/motif/${item.id}`}
                            state={{ from: "checkout" }}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={item.imageUrl}
                              alt={item.name}
                              height="100%"
                              sx={{
                                objectFit: "contain",
                                borderRadius: 1,
                                backgroundColor: "grey",
                                padding: 1,
                              }}
                            />
                          </Link>
                        </Grid>
                        <Grid item xs={7} sm={8} md={9}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            height={"100%"}
                          >
                            <Box mb={2}>
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Link
                                  to={`/dashboard/motif/${item.id}`}
                                  state={{ from: "checkout" }}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <Typography
                                    variant="h5"
                                    fontWeight={600}
                                    noWrap
                                  >
                                    {item.name}
                                  </Typography>
                                </Link>
                                <Tooltip title="Remove from Cart" arrow>
                                  <IconButton
                                    size="small"
                                    aria-label="remove"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveFromCart(item.id);
                                    }}
                                    sx={{
                                      borderRadius: 2,
                                      padding: 1,
                                      "& .MuiTouchRipple-root span": {
                                        borderRadius: 2,
                                      },
                                      "&:hover": {
                                        backgroundColor: "lightgray",
                                      },
                                    }}
                                  >
                                    <ClearRoundedIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Tooltip
                                  title={
                                    !item.active
                                      ? t("Article.Inactive")
                                      : item.reserved
                                      ? t("Article.Reserved")
                                      : ""
                                  }
                                  arrow
                                >
                                  <Typography
                                    variant="subtitle2"
                                    paddingX={2}
                                    color={
                                      !item.active || item.reserved
                                        ? "white"
                                        : "text.main"
                                    }
                                    sx={{
                                      fontWeight: "500",
                                      backgroundColor: item.reserved
                                        ? "primary.main"
                                        : !item.active
                                        ? "error.main"
                                        : "secondary.main",
                                      borderRadius: 1,
                                    }}
                                  >
                                    {item.article_number}
                                  </Typography>
                                </Tooltip>
                              </Box>

                              <Typography
                                variant="body2"
                                color="text.secondary"
                                marginTop={1}
                              >
                                {item.quantity}x
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {item.width}mm x {item.height}mm ={" "}
                                {parseFloat(
                                  (
                                    ((item.width / 10) * item.height) /
                                    10
                                  ).toFixed(2)
                                ).toString()}
                                cm²
                              </Typography>
                            </Box>
                            {/* <Box display="flex" alignItems="center" marginTop={1}>
                              <TextField
                                select
                                label="Quantity"
                                value={item.quantity}
                                size="small"
                                variant="outlined"
                                onChange={(e) => handleUpdateCart(item.itemId, e.target.value)}
                                sx={{ width: "150px", marginRight: 2 }}
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                      },
                                    },
                                  },
                                }}
                              >
                                {quantityTable.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box> */}
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography variant="h5" fontWeight={500} gutterBottom>
              {t("Checkout.OrderDetails")}
            </Typography>
            <List>
              {cart.map((item, index) => {
                const itemPriceInfo = priceInfo[item.id] || {
                  pricePerMotif: t("Checkout.Calculating"),
                  total: t("Checkout.Calculating"),
                };
                const formatPrice = (price) => {
                  if (typeof price === "number") {
                    const formattedPrice = parseFloat(price.toFixed(2));
                    return formattedPrice.toString();
                  }
                  return "N/A";
                };
                return (
                  <Box key={item.id}>
                    <Typography variant="body1" fontWeight={500}>
                      {index + 1}. {item.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={16}
                      color="text.secondary"
                    >
                      {itemPriceInfo.totalWOLump} kr
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {t("Checkout.LumpSum")}: + {itemPriceInfo.lumpSumPost} kr
                    </Typography>

                    {item.colors > 1 && (
                      <Typography variant="body2" color="text.secondary">
                        {t("Dashboard.PriceCalculator.ColorImpact")}: + {""}
                        {itemPriceInfo.discountedColorIncrease} kr
                      </Typography>
                    )}

                    {applyAssembly && (
                      <Typography variant="body2" color="text.secondary">
                        {t("Checkout.Assembly")}: +{" "}
                        {itemPriceInfo.assemblyTotalPost} kr
                      </Typography>
                    )}

                    <Divider sx={{ my: 1 }} />
                  </Box>
                );
              })}
            </List>
            {/* <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="body2">{t("Checkout.SubTotal")}</Typography>
              <Typography variant="body2">
                {parseFloat(subtotal.toFixed(2)).toString()} kr
              </Typography>
            </Box> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyAssembly}
                  onChange={handleToggleAssembly}
                />
              }
              label={t("Checkout.IncludeAssembly")}
            />

            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="body1" fontWeight={500}>
                {t("Checkout.GrandTotal")}
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {parseFloat(grandTotal.toFixed(2)).toString()} kr
              </Typography>
            </Box>
            <Collapse in={orderError}>
              <Box mt={2}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            </Collapse>

            <Button
              variant="contained"
              fullWidth
              onClick={handlePlaceOrder}
              disabled={loading || orderPlaced}
              sx={{
                minHeight: "50px",
                position: "relative",
                backgroundColor: orderError
                  ? "error.main"
                  : orderPlaced
                  ? "success.main"
                  : "primary.main", // Red background on error
                color: "white",
                "&:hover": {
                  backgroundColor: orderError
                    ? "error.dark"
                    : orderPlaced
                    ? "success.dark"
                    : "primary.dark", // Darker red on hover when there's an error
                },
                "&.Mui-disabled": {
                  backgroundColor: orderPlaced
                    ? "success.main"
                    : "primary.main",
                  color: "white",
                  opacity: 1,
                },
              }}
            >
              {animationStage === "loading" ? (
                <CircularProgress
                  size={35}
                  sx={{
                    color: "white",
                    transition: "transform 0.5s ease",
                    transform:
                      animationStage === "shrinking"
                        ? "scale(0.1)"
                        : "scale(1)",
                  }}
                />
              ) : animationStage === "success" ? (
                <CheckIcon
                  sx={{
                    fontSize: 37,
                    animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                  }}
                />
              ) : orderError ? (
                <ClearRoundedIcon
                  sx={{
                    fontSize: 37,
                    animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                  }}
                />
              ) : (
                <Typography
                  fontWeight={500}
                  sx={{
                    transition: "transform 0.5s ease, opacity 0.5s ease",
                    transform:
                      animationStage === "shrinking"
                        ? "scale(0.1)"
                        : "scale(1)",
                    opacity: animationStage === "shrinking" ? 0 : 1,
                  }}
                >
                  {t("Checkout.PlaceOrder")}
                </Typography>
              )}
            </Button>
            <TextField
              label={t("Checkout.NotesLabel")}
              multiline
              maxRows={10}
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder={t("Checkout.NotesPlaceholder")}
              sx={{ marginY: 3 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Collapse in={showForm}>
              <Box mt={3}>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body1" fontWeight={500} mb={2}>
                  {t("Checkout.MissingDetails")}
                </Typography>

                <TextField
                  label={t("Dashboard.MyProfile.FirstName")}
                  name="firstname"
                  fullWidth
                  value={formFields.firstname}
                  onChange={handleFieldChange}
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                  required
                  sx={{ mb: 2 }}
                />

                <TextField
                  label={t("Dashboard.MyProfile.LastName")}
                  name="lastname"
                  fullWidth
                  value={formFields.lastname}
                  onChange={handleFieldChange}
                  error={!!errors.lastname}
                  helperText={errors.lastname}
                  required
                  sx={{ mb: 2 }}
                />

                <TextField
                  label={t("Dashboard.MyProfile.Company")}
                  name="company_name"
                  fullWidth
                  value={formFields.company_name}
                  onChange={handleFieldChange}
                  helperText={errors.company_name}
                  sx={{ mb: 2 }}
                />

                {isLoaded && (
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                  >
                    <TextField
                      label={t("Dashboard.MyProfile.Address")}
                      name="address"
                      fullWidth
                      value={formFields.address}
                      onChange={handleFieldChange}
                      error={!!errors.address}
                      helperText={errors.address}
                      required
                      sx={{ mb: 2 }}
                    />
                  </StandaloneSearchBox>
                )}

                <TextField
                  label={t("Dashboard.MyProfile.City")}
                  name="city"
                  fullWidth
                  value={formFields.city}
                  onChange={handleFieldChange}
                  error={!!errors.city}
                  helperText={errors.city}
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  label={t("Dashboard.MyProfile.PostalCode")}
                  name="postal_code"
                  fullWidth
                  value={formFields.postal_code}
                  onChange={handleFieldChange}
                  error={!!errors.postal_code}
                  helperText={errors.postal_code}
                  required
                  sx={{ mb: 2 }}
                />
                {/* <ReactFlagsSelect
                  selected={formFields.country} // Use the country from formFields
                  searchable
                  selectedSize={20}
                  onSelect={(code) => handleFieldChange(null, "country", code)}
                />

                {errors.country && (
                  <Typography variant="body2" color="error" gutterBottom>
                    {errors.country}
                  </Typography>
                )} */}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberDetails}
                    onChange={(e) => setRememberDetails(e.target.checked)}
                  />
                }
                label={t("Checkout.RememberDetails")}
              />
            </Collapse>
          </Paper>
        </Grid>
      </Grid>
      {/* <OrderFormDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
      /> */}
    </Container>
  );
};

export default CartPage;
