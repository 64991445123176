import {
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import usePriceCalculator from "../../../components/priceCalculator";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";

function Calculator() {
  const { calculatePrice, priceInfo, loading, error } = usePriceCalculator();
  const { userDetails } = useAuth();
  const [quantity, setQuantity] = useState(15);
  const [colors, setColors] = useState(1);
  const [sizeDetails, setSizeDetails] = useState({
    width: 0,
    height: 0,
    area: 0,
  });
  const [inputError, setInputError] = useState(false);

  const [applyDiscount] = useState(true);
  const [applyLumpSum] = useState(true);
  const [applyAssembly, setApplyAssembly] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      sizeDetails.width > 0 &&
      sizeDetails.height > 0 &&
      userDetails?.user_id
    ) {
      calculatePrice(
        quantity,
        sizeDetails.width,
        sizeDetails.height,
        colors,
        null,
        userDetails.user_id,
        {
          applyDiscount,
          applyLumpSum,
          applyAssembly,
        }
      );
      setInputError(false);
    } else {
      setInputError(true);
    }
  }, [
    quantity,
    sizeDetails.width,
    sizeDetails.height,
    colors,
    applyDiscount,
    applyLumpSum,
    calculatePrice,
    userDetails.user_id,
    applyAssembly,
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue = parseFloat(value) || 0;
    setSizeDetails((prevDetails) => {
      const newDetails = { ...prevDetails, [name]: newValue };
      if (name === "width" || name === "height") {
        newDetails.area = (newDetails.width * newDetails.height) / 100;
      }
      return newDetails;
    });
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10) || 1);
  };

  const handleColorChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setColors(value);
    } else {
      setColors(1);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Paper
      elevation={3}
      sx={{ padding: 4, borderRadius: 2, backgroundColor: "#f9f9f9" }}
    >
      <Typography variant="h4" gutterBottom align="left" sx={{ mb: 4 }}>
        {t("Dashboard.PriceCalculator.Label")}
      </Typography>

      <Grid container spacing={4}>
        {/* Quantity Input */}
        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Quantity")}
            value={quantity}
            size="small"
            variant="outlined"
            type="number"
            onChange={handleQuantityChange}
            sx={{ width: "100%" }}
            inputProps={{
              min: 1,
            }}
          />
        </Grid>

        {/* Width Input */}
        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Width")}
            name="width"
            type="number"
            size="small"
            variant="outlined"
            onChange={handleChange}
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            error={inputError && sizeDetails.width === 0} // Display error if width is 0
            helperText={
              inputError && sizeDetails.width === 0
                ? t("Dashboard.PriceCalculator.WidthRequired")
                : ""
            }
          />
        </Grid>

        {/* Height Input */}
        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Height")}
            name="height"
            type="number"
            size="small"
            variant="outlined"
            onChange={handleChange}
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            error={inputError && sizeDetails.height === 0} // Display error if height is 0
            helperText={
              inputError && sizeDetails.height === 0
                ? t("Dashboard.PriceCalculator.HeightRequired")
                : ""
            }
          />
        </Grid>

        {/* Area Display */}
        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Area")}
            name="area"
            type="number"
            size="small"
            value={sizeDetails.area}
            variant="outlined"
            disabled
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm²</InputAdornment>,
            }}
          />
        </Grid>

        {/* Color Input */}
        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Colors")}
            value={colors}
            size="small"
            type="number"
            variant="outlined"
            onChange={handleColorChange}
            sx={{ width: "100%" }}
            inputProps={{
              min: 1,
            }}
          />
        </Grid>

        <Grid item xs={0} md={4}></Grid>

        {/* Toggle Discount */}
        {/* <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox checked={applyDiscount} onChange={toggleDiscount} />
            }
            label={t("Dashboard.PriceCalculator.ApplyDiscount")}
          />
        </Grid> */}

        {/* Toggle Lump Sum */}
        {/* <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox checked={applyLumpSum} onChange={toggleLumpSum} />
            }
            label={t("Dashboard.PriceCalculator.ApplyLumpSum")}
          />
        </Grid> */}

        {/* Toggle Assembly */}
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={applyAssembly}
                onChange={(event) => setApplyAssembly(event.target.checked)}
              />
            }
            label={t("Dashboard.PriceCalculator.ApplyAssembly")}
          />
        </Grid>

        {/* Price Per Motif */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "background",
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
              height: "100%",
            }}
          >
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              {t("Dashboard.PriceCalculator.PricePer")}
            </Typography>
            <Typography
              variant="body1"
              fontSize={23}
              fontWeight={500}
              gutterBottom
            >
              {sizeDetails.width && sizeDetails.height
                ? (
                    parseFloat(
                      applyDiscount
                        ? priceInfo.pricePerMotif -
                            priceInfo.colorPerIncreaseAmount
                        : priceInfo.rawPricePerMotif || 0
                    ) +
                    parseFloat(
                      colors > 1 ? priceInfo.colorPerIncreaseAmount || 0 : 0
                    )
                  ).toFixed(2)
                : "-"}{" "}
              kr
            </Typography>
            {/* Conditionally show the discounts if they exist */}
            {/* {applyDiscount && priceInfo.generalDiscount > 0 && (
              <Typography ml={2} mb={0.5} variant="body2" color="text.light">
                {t("Checkout.GeneralDiscount")}: {priceInfo.generalDiscount}%
              </Typography>
            )} */}
            {colors > 1 && (
              <Typography variant="body2" color="text.secondary">
                {t("Dashboard.PriceCalculator.ColorImpact")}: +
                {applyDiscount
                  ? parseFloat(
                      priceInfo.discountedColorIncrease / quantity
                    ).toFixed(2)
                  : priceInfo.colorPerIncreaseAmount}{" "}
                kr
              </Typography>
            )}
            {/* {applyDiscount && priceInfo.colorDiscount > 0 && colors > 1 && (
              <Typography ml={2} mb={0.5} variant="body2" color="text.light">
                {t("Checkout.ColorDiscount")}: {priceInfo.colorDiscount}%
              </Typography>
            )} */}
          </Paper>
        </Grid>

        {/* Total Price */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "background",
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, color: "text.secondary" }}
            >
              {t("Dashboard.PriceCalculator.Total")}
            </Typography>
            <Typography
              variant="body1"
              fontSize={23}
              fontWeight={500}
              gutterBottom
            >
              {sizeDetails.width && sizeDetails.height
                ? (
                    parseFloat(
                      applyDiscount
                        ? priceInfo.totalWOLump -
                            priceInfo.colorIncreaseAmount -
                            priceInfo.assemblyTotalPost
                        : priceInfo.rawTotal || 0
                    ) +
                    parseFloat(
                      colors > 1 ? priceInfo.colorIncreaseAmount || 0 : 0
                    ) +
                    parseFloat(
                      applyLumpSum
                        ? (applyDiscount
                            ? priceInfo.lumpSumPost
                            : priceInfo.lumpSum) || 0
                        : 0
                    ) +
                    parseFloat(
                      applyAssembly
                        ? (applyDiscount
                            ? priceInfo.assemblyTotalPost
                            : priceInfo.assemblyPrice) || 0
                        : 0
                    )
                  ).toFixed(2)
                : "-"}{" "}
              kr
            </Typography>

            {/* Conditionally show the discounts if they exist */}
            {/* {applyDiscount && priceInfo.generalDiscount > 0 && (
              <Typography ml={2} mb={0.5} variant="body2" color="text.light">
                {t("Checkout.GeneralDiscount")}: {priceInfo.generalDiscount}%
              </Typography>
            )} */}

            {/* {applyLumpSum && (
              <>
                <Typography variant="body2" color="text.secondary">
                  {t("Checkout.LumpSum")}: +{" "}
                  {applyDiscount ? priceInfo.lumpSumPost : priceInfo.lumpSum} kr
                </Typography>
                {applyDiscount && priceInfo.lumpSumDiscount > 0 && (
                  <Typography
                    ml={2}
                    mb={0.5}
                    variant="body2"
                    color="text.light"
                  >
                    {t("Checkout.LumpSumDiscount")}: {priceInfo.lumpSumDiscount}
                    %
                  </Typography>
                )}
              </>
            )} */}

            {colors > 1 && (
              <Typography variant="body2" color="text.secondary">
                {t("Dashboard.PriceCalculator.ColorImpact")}: +{" "}
                {applyDiscount
                  ? priceInfo.discountedColorIncrease
                  : priceInfo.colorIncreaseAmount}{" "}
                kr
              </Typography>
            )}

            {/* {applyDiscount && priceInfo.colorDiscount > 0 && colors > 1 && (
              <Typography ml={2} mb={0.5} variant="body2" color="text.light">
                {t("Checkout.ColorDiscount")}: {priceInfo.colorDiscount}%
              </Typography>
            )} */}

            {applyAssembly && (
              <>
                <Typography variant="body2" color="text.secondary">
                  {t("Checkout.Assembly")}: +{" "}
                  {applyDiscount
                    ? priceInfo.assemblyTotalPost
                    : priceInfo.assemblyPrice}{" "}
                  kr
                </Typography>
                {applyDiscount && priceInfo.assemblyDiscount > 0 && (
                  <Typography
                    ml={2}
                    mb={0.5}
                    variant="body2"
                    color="text.light"
                  >
                    {t("Checkout.AssemblyDiscount")}:{" "}
                    {priceInfo.assemblyDiscount}%
                  </Typography>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {inputError && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t("Dashboard.PriceCalculator.InputValidation")}
        </Alert>
      )}
    </Paper>
  );
}

export default Calculator;
